import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { authentication } from '../firebase-config'
// import Layout from '../layout'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/admin',
        name: 'admin',
        component: () =>
            import ('../views/Admin.vue')
    },
    {
        path: '/admin/account',
        name: 'account',
        component: () =>
            import ('../components/Account.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/san-pham',
        name: 'Product',
        component: () =>
            import ('../views/Product.vue'),
        // meta: { requiresAuth: true }
    },
    {
        path: '/thanh-vien',
        name: 'Members',
        component: () =>
            import ('../views/Members.vue'),
        // meta: { requiresAuth: true }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = authentication.currentUser;

    if (requiresAuth && !isAuthenticated) {
        next('/admin')
    } else {
        next()
    }
})

export default router