<template>
  <footer id="footer" class="footer-area">
    <div class="footer-top-space gray-bg"></div>
    <div class="footer-widget pb-20">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-8">
            <div class="footer-logo mt-30">
              <a href="#">
                <img src="../assets/images/logo.png" alt="Logo" />
              </a>

              <ul class="mt-20 footer-display ml-90">
                <li class="footer-display-a">
                  <a href="https://www.facebook.com/EMGlabvn.HUST">Facebook</a>
                </li>
                <!-- <li class="footer-display-a ml-3">
                  <a href="#">Zalo</a>
                </li> -->
              </ul>
            </div>
            <!-- footer logo -->
          </div>

          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="footer-link mt-45">
              <div class="f-title">
                <h4 class="title">Liên hệ nhanh</h4>
              </div>
              <ul class="footer-info">
                <li>
                  <div class="single-info footer-display">
                    <div class="info-icon">
                      <i class="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <div class="info-content">
                      <p class="footer-layout-p">(+84) 912 470 446</p>
                    </div>
                  </div>
                  <!-- single info -->
                </li>
                <li>
                  <div class="single-info footer-display">
                    <div class="info-icon">
                      <i class="fa fa-envelope" aria-hidden="true"></i>
                    </div>

                    <div class="info-content">
                      <p class="footer-layout-p">emglabvn@gmail.com</p>
                    </div>
                  </div>
                  <!-- single info -->
                </li>
                <li>
                  <div class="single-info footer-display">
                    <div class="info-icon">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <div class="info-content">
                      <p class="footer-layout-p">
                        C9 419 Đại học Bách Khoa Hà Nội
                      </p>
                    </div>
                  </div>
                  <!-- single info -->
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="footer-link mt-45">
              <div class="f-title">
                <h4 class="title">Dịch vụ</h4>
              </div>
              <ul class="mt-15 hide-dot-li">
                <div>
                  <li><a>Tư vấn thiết kế giải pháp chuyển đổi số</a></li>
                </div>
                <div >
                  <li>
                    <a>Thiết kế hệ thống IoT, thiết bị và mạch điện tử</a>
                  </li>
                </div>
                <div>
                  <li>
                    <a>Phát triển đội ngũ IT cho doanh nghiệp</a>
                  </li>
                </div>
                <!-- <li><a>IoT</a></li> -->
              </ul>
            </div>
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!-- footer widget -->
    <div class="copyright-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="copyright text-center footer-display-login">
              <div>
                <p>Designed and Developed by</p>
              </div>

              <div class="ml-1">
                <a @click="login">Emglab@VN</a>
              </div>
            </div>
            <!-- copyright -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!-- copyright-area -->
  </footer>
</template>

<script>
export default {
  methods: {
    login() {
      this.$router.push("/admin");
    },
  },
};
</script>

<style>
.footer-top-space{
  height: 80px;

}
.footer-info {
  list-style-type: none;
}
.hide-dot-li {
  list-style-type: none;
}
.footer-display {
  /* text-align: center !important; */
  display: flex;
}
.info-icon {
  margin-right: 12px;
}
.footer-layout-p {
  margin-bottom: 0px;
}
.footer-display-a {
  /* text-decoration: none !important; */
  list-style: none;
}
.footer-display-login {
  display: flex;
  justify-content: center;
}
</style>