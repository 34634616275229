<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <NavBar />
    <IntroduceEMG />
    <About />
    <Products />
    <Partner/>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import IntroduceEMG from "@/components/IntroduceEMG.vue";
import About from "@/components/About.vue";
import Products from "@/components/Products.vue";
import Footer from "@/components/Footer.vue";
import Partner from "@/components/Partner.vue";

export default {
  name: "Home",
  components: {
    NavBar,
    IntroduceEMG,
    About,
    Products,
    Footer,
    Partner,
  },
};
</script>
