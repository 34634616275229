<template>
  <div class="navigation-bar stick" v-on:scroll.passive="handleScroll">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="#">
              <img src="../assets/images/logo.png" alt="Logo" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul id="nav" class="navbar-nav ml-auto">
                <li class="nav-item">
                  <a class="page-scroll" href="/">Trang chủ</a>
                </li>
                <li class="nav-item">
                  <a class="page-scroll" @click="btProduct">Sản Phẩm</a>
                </li>
                <li class="nav-item">
                  <a class="page-scroll" @click="btMembers">Thành Viên</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="page-scroll" href="/">Đào tạo</a>
                </li> -->
                <li class="nav-item">
                  <a class="page-scroll" href="/partner">Đối tác</a>
                </li>
                <li class="nav-item">
                  <a class="page-scroll" href="#contact">Liên hệ</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="page-scroll" @click="login">Đăng nhập</a>
                </li> -->
              </ul>
              <!-- navbar nav -->
            </div>
            <!-- <div class="navbar-btn ml-20 d-none d-sm-block " >
              <a class="" >Đăng nhập</a>
            </div> -->
          </nav>
          <!-- navbar -->
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>
  <!-- navigation bar -->
</template>

<script>
export default {
  data() {
    return {
      stick: "",
    };
  },
  methods: {
    handleScroll(e) {
      if (e.target.scrollHeight - 300 <= e.target.scrollTop) {
        this.stick = " sticky";
      } else {
        this.stick = "";
      }
    },
    btProduct() {
      this.$router.push("/san-pham");
    },
    btMembers() {
      this.$router.push("/thanh-vien");
    },
    // login(){
    //   this.$router.push('/admin')
    // }
  },
  created() {
    this.handleScroll();
  },
};
</script>

<style>
@import "../assets/css/style.css";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/default.css";
</style>