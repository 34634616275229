<template>
  <div class="header-banner d-flex align-items-center header-area pt-80">
    <div class="shape header-shape-one">
      <img src="../assets/images/banner/shape/shape-1.png" alt="shape" />
    </div>
    <div class="shape header-shape-tow animation-one">
      <img src="../assets/images/banner/shape/shape-2.png" alt="shape" />
    </div>
    <div class="shape header-shape-three animation-one">
      <img src="../assets/images/banner/shape/shape-3.png" alt="shape" />
    </div>
    <div class="shape header-shape-fore">
      <img src="../assets/images/banner/shape/shape-4.png" alt="shape" />
    </div>
    <div class="shape header-shape-five animation-one">
      <img src="../assets/images/banner/shape/shape-3.png" alt="shape" />
    </div>
    <div class="container" style="margin-top:30px">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-sm-12">
          <div class="banner-content">
            <h1
              class="banner-title wow fadeInUp"
              data-wow-duration="0.5s"
              data-wow-delay="1s"
            >
              {{ title }}
            </h1>
            <p class="intro-font-p">
              Nghiên cứu áp dụng công nghệ, phát triển nguồn lực cho chuyển đổi
              số trong lĩnh vực Y tế & Giáo dục
            </p>
            <div class="intro-layout">
              <a
                class="banner-contact wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="2.3s"
                rel="nofollow"
                href="https://www.facebook.com/messages/t/532428579/"
                >Ứng tuyển</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
    <!-- container -->
    <!-- <div>
      <img
        :src="introduce[0].linkBanner"
        class="banner-image bg_cover banner-image-autosize"
        style="display: none"
      />
    </div> -->
  </div>
  <!-- header banner -->
</template>

<script>
import { introduceemg } from "../firebase-config";
export default {
  data() {
    return {
      introduce: [],
      title: "",
    };
  },
  methods: {
    fetchDataIntro() {
      this.introduce = [];
      this.check = true;
      introduceemg.get().then((res) => {
        this.introduce = res.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
    getWidthScreen(){
      if (window.innerWidth < 767){
        this.title = "Phòng nghiên cứu CN"
      }else{
        this.title = "Phòng nghiên cứu chuyên ngành"
      }
    }
  },
  created() {
    this.fetchDataIntro();
    this.getWidthScreen()
  },
  
};
</script>

<style>
.intro-layout-title {
  font-size: 18px !important;
}
.intro-layout {
  margin-top: 0px;
  text-align: right;
  margin-right: 0px;
}
.intro-font-p {
  font-style: italic;
}
</style>