// import { firebase } from 'firebase/app'

import firebase from 'firebase'
import "firebase/storage"

export const firebaseConfig = {
    apiKey: "AIzaSyDyLBefUp4f0I_iIGuq1RTnhJoN1603iEA",
    authDomain: "testdemo1-d2efe.firebaseapp.com",
    databaseURL: "https://testdemo1-d2efe-default-rtdb.firebaseio.com",
    projectId: "testdemo1-d2efe",
    storageBucket: "testdemo1-d2efe.appspot.com",
    messagingSenderId: "474470917830",
    appId: "1:474470917830:web:1be4995dcf07718f3119c7",
    measurementId: "G-58WKHG72XK"
}
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const storage = firebaseApp.storage()

let members = db.collection("members");
let oldmembers = db.collection("oldmembers");
let products = db.collection("products");
let newProject = db.collection("newProject");
let emailSender = db.collection("emailSender");
let introduceemg = db.collection("introduceemg");
let partner = db.collection("partner");
let authentication = firebaseApp.auth();


export { members, products, newProject, oldmembers, emailSender, authentication, introduceemg, storage, partner }