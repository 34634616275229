<template>
  <div>
    <section id="about" class="about-area pt-0 pb-50 gray-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-image mt-20 clearfix">
              <h4 class="about-welcome " style="color:red;">GIỚI THIỆU</h4>
              <div class="single-image float-left mt-3">
                <img :src="introduce[0].linkImage1" alt="" />
              </div>
              <div data-aos="fade-right" class="about-btn">
                <a class="main-btn" href="#"><span>10</span> Năm kinh nghiệm</a>
              </div>
              <div class="single-image image-tow float-right">
                <img :src="introduce[0].linkImage2" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content mt-50">
              <!-- <h4 class="about-welcome">Giới thiệu</h4> -->
              <p class="mt-25" v-html="introduce[0].intro"></p>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="about-content mt-45">
              <h4 class="about-welcome">Hướng nghiên cứu chính</h4>
              <p class="mt-25" v-html="introduce[0].perpose"></p>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="about-content mt-30">
              <h4 class="about-welcome">Dịch vụ</h4>
              <p class="mt-25 pl-15" v-html="introduce[0].service"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { introduceemg } from "../firebase-config";
export default {
  data() {
    return {
      introduce: [],
    };
  },
  methods: {
    fetchDataIntro() {
      this.introduce = [];
      this.check = true;
      introduceemg.get().then((res) => {
        this.introduce = res.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
  },
  created() {
    this.fetchDataIntro();
  },
};
</script>

<style>
</style>