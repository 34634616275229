<template>
  <section id="partner" class="partner-area pt-40 pb-0 gray-bg" v-if="this.partnerList != 0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center pb-20">
            <h5 class="sub-title mb-15">Đối tác của tôi</h5>
            <p>Các đối tác đã tin tưởng, đồng hành và phát triển cùng chúng tôi</p>
          </div>
          <!-- section title -->
        </div>
      </div>

      <div class="row justify-content-center flex" >
        <div class="col-lg-3 col-md-6 col-sm-6 mt-3" v-for="(partner, index) in partnerList" :key="index">
          <div class="partner-margin border-item">
            <div class="partner-container-image">
              <img
                class="partner-image"
                :src="partner.linkImage"
                alt=""
              />
            </div>

            <h4 class="partner-name">{{ partner.namePartner }}</h4>
            <p class="partner-text">
              {{ partner.note }}
            </p>
            <div class="partner-button hide-dot-li">
              <a>Chi tiết</a>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-3 col-md-6 col-sm-6 mt-3">
          <div class="partner-margin border-item">
            <div class="partner-container-image">
              <img
                class="partner-image"
                src="https://www.duoc.work/cpc1hn.png"
                alt=""
              />
            </div>

            <h4 class="partner-name">Công ty Dược phẩm CPC1HN</h4>
            <p class="partner-text">
              CPC1HN được thành lập vào ngày 5/8/2009, có trụ sở chính tại cụm
              Công nghiệp Hà Bình Phương, Thường Tín, Hà Nội với diện tích hơn
              10 hecta.
            </p>
            <div class="partner-button">
              <a href="https://cpc1hn.com.vn/">Chi tiết</a>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6 mt-3">
          <div class="partner-margin border-item">
            <div class="partner-container-image">
              <img
                class="partner-image"
                src="https://firebasestorage.googleapis.com/v0/b/testdemo1-d2efe.appspot.com/o/vnplogo.png?alt=media&token=d8483401-652a-4341-940b-b036c08452d1"
                alt=""
              />
            </div>

            <h4 class="partner-name">Công ty Dược phẩm VNP</h4>
            <p class="partner-text">
              CPC1HN được thành lập vào ngày 5/8/2009, có trụ sở chính tại cụm
              Công nghiệp Hà Bình Phương, Thường Tín, Hà Nội với diện tích hơn
              10 hecta.
            </p>
            <div class="partner-button">
              <a href="https://cpc1hn.com.vn/">Chi tiết</a>
            </div>
          </div>
        </div> -->
      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </section>
</template>

<script>
import { partner } from "../firebase-config";

export default {
  data() {
    return {
      partnerList: []
    };
  },
  methods: {
    fetchData() {
      partner.get().then((res) => {
        this.partnerList = res.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
      });
    },
  },
  created() {
    this.fetchData();
  },
}
</script>

<style>

.flex{
  display: flex;
}
.border-item {
  border-radius: 16px;
  border: 1px solid #ccc !important;
}
.border-item:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.partner-margin {
  padding-bottom: 20px;
}
.partner-name {
  margin: 8px;
  text-align: center;
}
.partner-container-image {
  text-align: center;
}

.partner-image {
  padding: 24px;
  height: auto;
  max-width: 100%;
}

.partner-text {
  margin-top: 16px;
  font-size: 14px;
  margin-left: 24px;
  margin-right: 24px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.partner-button {
  margin-right: 20px;
  text-align: right;
}

@media (max-width: 767px) {
  .partner-image {
    max-width: 240px;
  }
}
</style>